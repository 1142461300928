import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layoutTermsOfService"

import "../assets/css/main.css"

const IndexPage = () => (
  <Layout/>
)

export default IndexPage 